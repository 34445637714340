import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FetchApiService } from '@common/data-access/service/fetch-api/fetch-api.service';
import { StateService } from '@common/data-access/service/state/state.service';
import { ENDPOINT } from '@common/utils/endpoint';

export const phoneCodeResolver: ResolveFn<boolean> = (route, state) => {
  const fetchAPIService = inject(FetchApiService);
  const stateService = inject(StateService);

  stateService.setLoadingPage(true);
  return fetchAPIService.get(ENDPOINT.phoneCodeList, null);
};
